.pb-screen-default {

  display: flex;

  flex-flow: column;

  min-height: 100vh;

  color: #394551;
  background: #c1ced6;

}

.pb-screen-default__header {

  display: flex;

  justify-content: space-between;
  align-items: center;

  padding: 1rem;

}

.pb-screen-default__container {

  display: flex;

  flex: 1;

  flex-flow: column;

}

.pb-screen-default__aside {

  min-width: 12rem;

  color: #c1ced6;
  background: #394551;

}

.pb-screen-default__logo {

  display: block;

  color: inherit;

  font-size: 1.5rem;
  letter-spacing: 0.1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

}

.pb-screen-default__btn-language:last-of-type {

  margin-right: 0;

}

.pb-screen-default__main {

  flex: 1;

  padding: 1rem;

  background: #f6f6f6;

}
