.pb-form-select {

  padding: 0.5rem 0;

  width: 100%;

  border: none;
  border-radius: 0;
  border-bottom: 2px solid;

  color: inherit;
  background: none;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;

}
