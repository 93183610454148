.pb-menu-main {

  padding: 1rem 1rem 0 1rem;

}

.pb-menu-main__list {

  display: flex;

  justify-content: space-around;

  list-style: none;

}

.pb-menu-main__list li {

  flex: 1;

}

.pb-menu-main__link {

  display: block;

  padding: 1rem 0;

  color: inherit;

  text-align: center;
  text-decoration: none;

}

.pb-menu-main__link--active {

  color: #394551;
  background: #f6f6f6;

}

.pb-menu-main__link__image {

  display: block;

  margin: 0 auto 0.5rem auto;

  font-size: 2rem;

}
