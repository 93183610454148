* {

  margin: 0;
  padding: 0;

  box-sizing: border-box;

}

body {

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

main {

  display: flex;

  flex-flow: column;

}

.pb-ag-grid {

  flex: 1 1;

}

.pb-flex {

  display: flex;

}

.pb-flex-1 {

  flex: 1 1;

}

.pb-margin-04 {

  margin: 0.4rem auto;

}

#root {

  min-height: 100vh;

}

.pb-tile {

  padding: 1rem;
  margin-bottom: 1rem;

  background: white;

}

.pb-space {

  margin-bottom: 1rem;

}

.pb-group {

  margin: 0 auto 1rem auto;
  padding: 1rem;

  background: white;

  width: 100%;
  max-width: 60rem;

}


.pb-button {

  display: block;

  padding: 0.3rem 0.5rem;

  border: 2px solid;
  border-radius: 0;

  color: inherit;
  background: #c1ced6;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  text-align: center;

  cursor: pointer;

}

.pb-button-inline {

  display: inline-block;

  margin-right: 1rem;

}


.pb-textarea {

  padding: 0.5rem;

  border: 2px solid;

  min-width: 100%;
  max-width: 100%;
  min-height: 8rem;

}

.pb--number {

  display: block;

  -webkit-font-feature-settings: "tnum";

          font-feature-settings: "tnum";

  font-variant-numeric: tabular-nums;
  text-align: right;

}

.pb--link-no-special-format {

  color: inherit;
  text-decoration: inherit;

}

.pb-inline-icon {

  margin-right: 0.5em;

}

/* .pb-ag-grid-row-new {

  background-color: #c1ced6 !important;

}

.pb-ag-grid-row-rematch-new {

  background-color: green !important;

}

.pb-ag-grid-row-rematch-not {

  background-color: red !important;

} */

.ag-root-wrapper.ag-layout-normal {

  min-height: 20rem;

}

.pb-menu-main {

  padding: 1rem 1rem 0 1rem;

}

.pb-menu-main__list {

  display: flex;

  justify-content: space-around;

  list-style: none;

}

.pb-menu-main__list li {

  flex: 1 1;

}

.pb-menu-main__link {

  display: block;

  padding: 1rem 0;

  color: inherit;

  text-align: center;
  text-decoration: none;

}

.pb-menu-main__link--active {

  color: #394551;
  background: #f6f6f6;

}

.pb-menu-main__link__image {

  display: block;

  margin: 0 auto 0.5rem auto;

  font-size: 2rem;

}

.pb-screen-default {

  display: flex;

  flex-flow: column;

  min-height: 100vh;

  color: #394551;
  background: #c1ced6;

}

.pb-screen-default__header {

  display: flex;

  justify-content: space-between;
  align-items: center;

  padding: 1rem;

}

.pb-screen-default__container {

  display: flex;

  flex: 1 1;

  flex-flow: column;

}

.pb-screen-default__aside {

  min-width: 12rem;

  color: #c1ced6;
  background: #394551;

}

.pb-screen-default__logo {

  display: block;

  color: inherit;

  font-size: 1.5rem;
  letter-spacing: 0.1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

}

.pb-screen-default__btn-language:last-of-type {

  margin-right: 0;

}

.pb-screen-default__main {

  flex: 1 1;

  padding: 1rem;

  background: #f6f6f6;

}

.pb-form {

  margin: 0 auto 1rem auto;
  padding: 1rem;

  background: white;

  width: 100%;
  max-width: 60rem;

}

.pb-form-input {

  margin: 0.4rem auto;
  padding: 0.4rem 1rem;

  width: 100%;

  border: none;
  border-bottom: 2px solid;
  border-radius: 0;

  color: inherit;
  background: none;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  font-size: 1rem;
  text-decoration: none;

}

[type="submit"].pb-form-input,
[type="button"].pb-form-input {

  border: 2px solid;

  background: #c1ced6;

  font-weight: 700;

  cursor: pointer;

}

[type="submit"].pb-form-input-success,
[type="button"].pb-form-input-success {

  background: hsl(130, 20%, 80%);

}

[type="submit"].pb-form-input-warning,
[type="button"].pb-form-input-warning {

  background: hsl(55, 20%, 80%);

}

[type="submit"].pb-form-input-danger,
[type="button"].pb-form-input-danger {

  background: hsl(0, 20%, 80%);

}

.pb-form-select {

  padding: 0.5rem 0;

  width: 100%;

  border: none;
  border-radius: 0;
  border-bottom: 2px solid;

  color: inherit;
  background: none;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;

}

.pb-cell-action {

  display: inline-flex;

  justify-content: space-around;

  width: 100%;

}

