.pb-form {

  margin: 0 auto 1rem auto;
  padding: 1rem;

  background: white;

  width: 100%;
  max-width: 60rem;

}
