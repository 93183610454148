* {

  margin: 0;
  padding: 0;

  box-sizing: border-box;

}

body {

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

main {

  display: flex;

  flex-flow: column;

}

.pb-ag-grid {

  flex: 1;

}

.pb-flex {

  display: flex;

}

.pb-flex-1 {

  flex: 1;

}

.pb-margin-04 {

  margin: 0.4rem auto;

}

#root {

  min-height: 100vh;

}

.pb-tile {

  padding: 1rem;
  margin-bottom: 1rem;

  background: white;

}

.pb-space {

  margin-bottom: 1rem;

}

.pb-group {

  margin: 0 auto 1rem auto;
  padding: 1rem;

  background: white;

  width: 100%;
  max-width: 60rem;

}


.pb-button {

  display: block;

  padding: 0.3rem 0.5rem;

  border: 2px solid;
  border-radius: 0;

  color: inherit;
  background: #c1ced6;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  text-align: center;

  cursor: pointer;

}

.pb-button-inline {

  display: inline-block;

  margin-right: 1rem;

}


.pb-textarea {

  padding: 0.5rem;

  border: 2px solid;

  min-width: 100%;
  max-width: 100%;
  min-height: 8rem;

}

.pb--number {

  display: block;

  font-variant-numeric: tabular-nums;
  text-align: right;

}

.pb--link-no-special-format {

  color: inherit;
  text-decoration: inherit;

}

.pb-inline-icon {

  margin-right: 0.5em;

}

/* .pb-ag-grid-row-new {

  background-color: #c1ced6 !important;

}

.pb-ag-grid-row-rematch-new {

  background-color: green !important;

}

.pb-ag-grid-row-rematch-not {

  background-color: red !important;

} */

.ag-root-wrapper.ag-layout-normal {

  min-height: 20rem;

}
