.pb-form-input {

  margin: 0.4rem auto;
  padding: 0.4rem 1rem;

  width: 100%;

  border: none;
  border-bottom: 2px solid;
  border-radius: 0;

  color: inherit;
  background: none;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  font-size: 1rem;
  text-decoration: none;

}

[type="submit"].pb-form-input,
[type="button"].pb-form-input {

  border: 2px solid;

  background: #c1ced6;

  font-weight: 700;

  cursor: pointer;

}

[type="submit"].pb-form-input-success,
[type="button"].pb-form-input-success {

  background: hsl(130, 20%, 80%);

}

[type="submit"].pb-form-input-warning,
[type="button"].pb-form-input-warning {

  background: hsl(55, 20%, 80%);

}

[type="submit"].pb-form-input-danger,
[type="button"].pb-form-input-danger {

  background: hsl(0, 20%, 80%);

}
